import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import '../style/kontakt.css'

const Kontakt = () => {
  
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const public_key = process.env.REACT_APP_API_KEY;
    

    //email function
    const form = useRef();

    const sendMail = (e) => {
        e.preventDefault();

    //emailjs config
    emailjs.sendForm(serviceId,templateId,form.current,public_key)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text)
        });
    }

    return (
        <div className="kontakt_content" id="kontakt">
            <div className="kontakt">
                <div className="text">
                    <h1>Kontaktieren Sie uns direkt</h1>
                </div>
            <form ref={form} onSubmit={sendMail}>
                    <label htmlFor="fname" name="Vorname">Name</label>
                    <input type="text" id="fname" name="from_name" placeholder="Name.." required></input>

                    <label htmlFor="email">E-Mail Adresse</label>
                    <input type="email" id="lname" name="from_mail" placeholder="E-Mail.." required></input>

                    <label htmlFor="subject">Nachricht</label>
                    <textarea id="subject" name="message" placeholder="Verfassen Sie eine Nachricht..." ></textarea>

                    <input type="submit" value="Senden"></input>
                </form>
            </div>
        </div>
    )
}

export default Kontakt;